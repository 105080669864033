<template>
  <div class="home">
    <headbar />

    <div class="container">
      <div class="swipe">
        <van-swipe :autoplay="3000" indicator-color="white">
          <van-swipe-item v-for="item in slideList" :key="item.id">
            <img :src="getimg(item.img)" class="swipe-img" />
          </van-swipe-item>
        </van-swipe>
      </div>

      <div class="total">
        <div class="total-title">TVL</div>
        <div class="total-value">{{ pageData.tvlTotal }}</div>
      </div>

      <div class="total-row">
        <div class="total-item">
          <div class="total-item-text">{{ $t('总释放') }}</div>
          <div class="total-item-value">
            <view>{{ pageData.touziShifang }}</view>
          </div>
        </div>
        <div class="total-item">
          <div class="total-item-text">{{ $t('每日释放') }}</div>
          <div class="total-item-value">
            <view>{{ pageData.touziShifangDay }}</view>
          </div>
        </div>
        <div class="total-item">
          <div class="total-item-text">{{ $t('链游总赢额') }}</div>
          <div class="total-item-value">
            <view>{{ pageData.jingcaiWin }}</view>
          </div>
        </div>
        <div class="total-item">
          <div class="total-item-text">{{ $t('每日链游赢额') }}</div>
          <div class="total-item-value">
            <view>{{ pageData.jingcaiWinDay }}</view>
          </div>
        </div>
      </div>

      <home-ranking />

      <!-- 公告 -->
      <DialogNotice />

      <check-register @refresh="refresh"></check-register>

      <tab-bar />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { Swipe, SwipeItem } from 'vant';
import HomeRanking from './components/HomeRanking'
import CheckRegister from '@/components/CheckRegister'
import DialogNotice from '@/components/DialogNotice'

export default {
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    HomeRanking,
    CheckRegister,
    DialogNotice
  },
  setup() {
    return {
      slideList: ref([]),
      show: ref(false),
      pageData: ref({}),
      showNotice: ref(true)
    }
  },
  created() {
    this.getslide()
    this.getPageData()
  },
  methods: {
    refresh() {

    },
    getimg(img) {
      if (img != "") {
        return this.$config.send_url + "upload/" + img;
      }
    },
    getslide() {
      let _this = this
      _this.$request.post(
        "Api/Slide/List",
        {
          lx: 1,
        },
        (res) => {
          if (res.data.code == 100) {
            let data = res.data.data
            _this.slideList = data
          }
        }
      )
    },
    getPageData() {
      let _this = this
      _this.$request.post(
        "api/Page/Home",
        {},
        (res) => {
          if (res.data.code == 100) {
            let data = res.data.data
            _this.pageData = data
          }
        }
      )
    }
  }
}
</script>

<style scoped>
.home {
  background-image: url(~@/assets/img/base/page-footer-bg.png);
  background-size: 100% 320px;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.swipe {
  margin-top: 10px;
}

.swipe-img {
  width: 100%;
  border-radius: 20px;
}

.container {
  padding: 0 20px 5px;
}

.total {
  margin-top: 8px;
  height: 83px;
  border-radius: 20px;
  background: linear-gradient(180deg, #884DDA 0%, #482974 100%);
}

.total-title {
  padding-top: 15px;
  font-size: 15px;
  font-weight: 700;
  color: #fff;
  text-align: center;
}

.total-value {
  color: #fff;
  text-align: center;
  font-size: 25px;
  font-weight: bold;
}

.total-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between
}

.total-item {
  margin-top: 8px;
  padding: 24px 15px 10px;
  width: 49%;
  min-height: 100px;
  border: 1px solid #2FCD0F;
  box-shadow: 0px 0px 12.6px 0px #2FCD0F inset;
  border-radius: 20px;
  color: #2FCD0F;
  background-color: #121C2E;
}

.total-item-text {
  font-size: 12px;
}

.total-item-value {
  margin-top: 5px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #2FCD0F;
  border-radius: 10px;
  background-color: #000;
  color: #fff;
}

.total-item:nth-child(2) {
  border: 1px solid #CD0FBA;
  box-shadow: 0px 0px 12.6px 0px #CD0FBA inset;
  color: #CD0FBA;
}

.total-item:nth-child(3) {
  border: 1px solid #0FBACD;
  box-shadow: 0px 0px 12.6px 0px #0FBACD inset;
  color: #0FBACD;
}

.total-item:nth-child(4) {
  border: 1px solid #884DDA;
  box-shadow: 0px 0px 12.6px 0px #884DDA inset;
  color: #884DDA;
}

.total-item:nth-child(2) .total-item-value {
  border: 1px solid #CD0FBA;
}

.total-item:nth-child(3) .total-item-value {
  border: 1px solid #0FBACD;
}

.total-item:nth-child(4) .total-item-value {
  border: 1px solid #884DDA;
}

.card {
  width: 350px;
}

.card-content {
  padding: 0 10px;
  max-height: 600px;
  overflow-y: auto;
}

.card-title {
  padding: 12px 8px 8px;
  font-weight: bold;
  text-align: center;
  font-size: 18px;
}
</style>