<template>
    <q-dialog v-model="show" persistent>
        <q-card>
            <div class="article" v-html="articleData"></div>

            <q-card-actions align="right">
                <q-btn flat label="OK" color="primary" @click="show = false" />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>
<script>
import { ref } from 'vue';
export default {
    data() {
        return {
            show: ref(false),
            articleData: ref("")
        }
    },
    created() {
        this.getArticle()
    },
    methods: {
        getArticle() {
            let _this = this
            _this.$request.post(
                "api/Article/Get",
                {
                    select_id: 16,
                },
                (res) => {
                    _this.articleData = res.data.data.articlecontent;
                    if (_this.articleData.length > 0) {
                        _this.show = true
                    }
                }
            )
        }
    }
}
</script>
<style scoped>
.article {
    padding: 16px;
    word-wrap: break-word;
}
</style>