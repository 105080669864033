<template>
  <div class="ranking">
    <div class="title">{{ $t('排行榜') }}</div>

    <div class="tabs">
      <div class="tabs-item" :class="{ 'tabs-active': index == active }" v-for="(item, index) in tabs" :key="index"
        @click="tabsChange(index)">
        {{ $t(item) }}
      </div>
    </div>

    <div class="table">
      <div class="table-key row">
        <div class="col-3">{{ $t('排名') }}</div>
        <div class="col">{{ $t('地址') }}</div>
        <div class="col">{{ $t('预计奖金') }}</div>
      </div>
      <div class="table-value row" v-for="(item, index) in list" :key="item.id">
        <div class="col-3">{{ index + 1 }}</div>
        <div class="col">{{ abbr(item.userid, 4, 4) }}</div>
        <div class="col">{{ item.amount }}</div>
      </div>
      <empty :text="$t('暂无记录')" v-if="list.length == 0" />
      <!-- <div class="row justify-center q-mt-md">
        <q-pagination v-model="pageIndex" :max="5" :max-pages="5" direction-links color="purple-9" @update:model-value="pageChang" />
      </div> -->
    </div>

    <div class="bg-block"></div>
  </div>
</template>

<script>
import { ref } from 'vue'
import CustomComponent from "@/components/CustomComponent";

export default {
  setup() {
    return {
      active: ref(0),
      tabs: ref(['前十名获胜', '前十名个人TVL']),

      pageIndex: ref(1),
      list: ref([])
    }
  },
  created() {
    this.getPanghangbangData()
  },
  methods: {
    tabsChange(e) {
      this.active = e
      this.getPanghangbangData()
    },
    pageChang(e) {
      this.pageIndex = e
      this.getPanghangbangData()
    },
    abbr(val, start, end) {
      if (val && val.length > 20) {
        let newVal = val.replace(
          val.substring(start, val.length - end),
          "****"
        )
        return newVal
      } else {
        return val
      }
    },
    getPanghangbangData() {
      console.log(this.active)
      let _this = this
      _this.$request.post(
        "api/Page/RankingList",
        {
          type: _this.active,
          pageIndex: 1,
          pageSize: 10
        },
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                messages: [res.data.msg],
                persistent: true,
              }
            });
            return
          }
          _this.list = res.data.data
        }
      )
    }
  }
}
</script>

<style scoped>
.ranking {
  position: relative;
}

.title {
  padding-top: 27px;
  color: #fff;
  font-size: 25px;
  font-weight: bold;
  text-align: center;
}

.tabs {
  margin-top: 8px;
  padding: 0 5px;
  height: 44px;
  display: flex;
  align-items: center;
  border-radius: 99px;
  background-color: #121C2E;
}

.tabs-item {
  flex: 1;
  height: 35px;
  line-height: 35px;
  text-align: center;
  color: #4E5F7E;
  font-weight: bold;
  border-radius: 99px;
}

.tabs-active {
  color: #fff;
  background: linear-gradient(180deg, #884DDA 0%, #482974 100%);
}

.bg-block {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 92px;
  background: url(~@/assets/img/base/ranking-round.png) no-repeat;
  background-size: 100% 100%;
  z-index: -1;
}

.table {
  margin-top: 8px;
  padding: 15px 15px 20px;
  border-radius: 20px;
  min-height: 250px;
  background-color: #121C2E;
}

.table-key {
  height: 25px;
  display: flex;
  align-items: center;
  color: #4E5F7E;
  background-color: #081121;
  border-radius: 10px 10px 0 0;
  font-size: 12px;
  text-align: center;
}

.table-value {
  margin-top: 21px;
  display: flex;
  color: #fff;
  text-align: center
}
</style>